@charset "UTF-8";
/*!
 * Bootstrap  v5.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
/*!
 * Shuffle CSS Extension for Bootstrap
 * Copyright 2022 The Shuffle Team
 */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.alert {
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.medium {
  font-size: 0.875rem;
}

.row .container,
.container .container {
  max-width: none;
  width: auto;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
}

.fw-medium {
  font-weight: 500;
}

:root {
  --bs-blue: #3B82F6;
  --bs-indigo: #6610f2;
  --bs-purple: #a855f7;
  --bs-pink: #d63384;
  --bs-red: #EF5844;
  --bs-orange: #F59E0B;
  --bs-yellow: #ffc107;
  --bs-green: #2AD167;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #4D5F7A;
  --bs-gray-dark: #333F51;
  --bs-gray-100: #EEF0F3;
  --bs-gray-200: #D5DAE1;
  --bs-gray-300: #BBC3CF;
  --bs-gray-400: #8896AB;
  --bs-gray-500: #556987;
  --bs-gray-600: #4D5F7A;
  --bs-gray-700: #404F65;
  --bs-gray-800: #333F51;
  --bs-gray-900: #2A3342;
  --bs-primary: #3B82F6;
  --bs-secondary: #556987;
  --bs-success: #2AD167;
  --bs-info: #a855f7;
  --bs-warning: #F59E0B;
  --bs-danger: #EF5844;
  --bs-light: #EEF0F3;
  --bs-dark: #1f2937;
  --bs-primary-light: #F5F9FF;
  --bs-secondary-light: #8896AB;
  --bs-success-light: #F4FDF7;
  --bs-info-light: #faf5ff;
  --bs-warning-light: #FFFAF3;
  --bs-danger-light: #FEF7F6;
  --bs-light-light: #F7F8F9;
  --bs-primary-dark: #EBF3FE;
  --bs-secondary-dark: #2A3342;
  --bs-success-dark: #EAFAF0;
  --bs-info-dark: #f3e8ff;
  --bs-warning-dark: #FEF5E7;
  --bs-danger-dark: #FDEEEC;
  --bs-light-dark: #333F51;
  --bs-primary-rgb: 59, 130, 246;
  --bs-secondary-rgb: 85, 105, 135;
  --bs-success-rgb: 42, 209, 103;
  --bs-info-rgb: 168, 85, 247;
  --bs-warning-rgb: 245, 158, 11;
  --bs-danger-rgb: 239, 88, 68;
  --bs-light-rgb: 238, 240, 243;
  --bs-dark-rgb: 31, 41, 55;
  --bs-primary-light-rgb: 245, 249, 255;
  --bs-secondary-light-rgb: 136, 150, 171;
  --bs-success-light-rgb: 244, 253, 247;
  --bs-info-light-rgb: 250, 245, 255;
  --bs-warning-light-rgb: 255, 250, 243;
  --bs-danger-light-rgb: 254, 247, 246;
  --bs-light-light-rgb: 247, 248, 249;
  --bs-primary-dark-rgb: 235, 243, 254;
  --bs-secondary-dark-rgb: 42, 51, 66;
  --bs-success-dark-rgb: 234, 250, 240;
  --bs-info-dark-rgb: 243, 232, 255;
  --bs-warning-dark-rgb: 254, 245, 231;
  --bs-danger-dark-rgb: 253, 238, 236;
  --bs-light-dark-rgb: 51, 63, 81;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 31, 41, 55;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Poppins, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #1f2937;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #D5DAE1;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.5rem;
  --bs-border-radius-sm: 0.375rem;
  --bs-border-radius-lg: 0.75rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 1.5rem;
  --bs-border-radius-pill: 9999px;
  --bs-link-color: #111827;
  --bs-link-hover-color: #0e131f;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #FEF5E7;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: Poppins, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  line-height: 1.25;
  color: #fff;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: none;
}
a:hover {
  color: var(--bs-link-hover-color);
  text-decoration: none;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.375rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #4b5563;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.container,
.container-fluid {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
   .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
   .container {
    max-width: 768px;
  }
}
@media (min-width: 992px) {
   .container {
    max-width: 1024px;
  }
}
@media (min-width: 1200px) {
   .container {
    max-width: 1280px;
  }
}
 .container {
  max-width: 1536px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.g-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3 {
  --bs-gutter-x: 0.75rem;
}

.g-3 {
  --bs-gutter-y: 0.75rem;
}

.g-4 {
  --bs-gutter-x: 1rem;
}

.g-4 {
  --bs-gutter-y: 1rem;
}

.g-6 {
  --bs-gutter-x: 1.5rem;
}

.g-6 {
  --bs-gutter-y: 1.5rem;
}

.g-8 {
  --bs-gutter-x: 2rem;
}

.g-8 {
  --bs-gutter-y: 2rem;
}

.g-16 {
  --bs-gutter-x: 4rem;
}

.g-16 {
  --bs-gutter-y: 4rem;
}

.g-20 {
  --bs-gutter-x: 5rem;
}

.g-20 {
  --bs-gutter-y: 5rem;
}

@media (min-width: 576px) {
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@media (min-width: 768px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .g-md-6 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-6 {
    --bs-gutter-y: 1.5rem;
  }
}
@media (min-width: 992px) {
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.75rem 0.875rem;
  font-family: Poppins, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #8896AB;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #D5DAE1;
  appearance: none;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #8896AB;
  background-color: #fff;
  border-color: #9dc1fb;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(59, 130, 246, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.25em;
}
.form-control::placeholder {
  color: #8896AB;
  opacity: 1;
}
.form-control:disabled {
  background-color: #D5DAE1;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.75rem 0.875rem;
  margin: -0.75rem -0.875rem;
  margin-inline-end: 0.875rem;
  color: #8896AB;
  background-color: #D5DAE1;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #cacfd6;
}

.form-control-lg {
  min-height: calc(1.25em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  border-radius: 0.75rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.25em + 1.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.25em + 1rem + 2px);
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.75rem 2.625rem 0.75rem 0.875rem;
  -moz-padding-start: calc(0.875rem - 3px);
  font-family: Poppins, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #8896AB;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23333F51' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.875rem center;
  background-size: 16px 12px;
  border: 1px solid #D5DAE1;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #9dc1fb;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(59, 130, 246, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.875rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #D5DAE1;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #8896AB;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #9dc1fb;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(59, 130, 246, 0.25);
}
.form-check-input:checked {
  background-color: #3B82F6;
  border-color: #3B82F6;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #3B82F6;
  border-color: #3B82F6;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn {
  --bs-btn-padding-x: 1rem;
  --bs-btn-padding-y: 1rem;
  --bs-btn-font-family: Poppins, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --bs-btn-font-size: 0.75rem;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: 1.25;
  --bs-btn-color: #1f2937;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.5rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: 0px 1px 2px rgba(105, 81, 255, 0.05);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
:not(.btn-check) + .btn:hover, .btn:first-child:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
 :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
 :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #2AD167;
  --bs-btn-border-color: #2AD167;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #24b258;
  --bs-btn-hover-border-color: #22a752;
  --bs-btn-focus-shadow-rgb: 74, 216, 126;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #22a752;
  --bs-btn-active-border-color: #209d4d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #2AD167;
  --bs-btn-disabled-border-color: #2AD167;
}

.btn-warning {
  --bs-btn-color: #fff;
  --bs-btn-bg: #F59E0B;
  --bs-btn-border-color: #F59E0B;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #d08609;
  --bs-btn-hover-border-color: #c47e09;
  --bs-btn-focus-shadow-rgb: 247, 173, 48;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #c47e09;
  --bs-btn-active-border-color: #b87708;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #F59E0B;
  --bs-btn-disabled-border-color: #F59E0B;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #EF5844;
  --bs-btn-border-color: #EF5844;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #cb4b3a;
  --bs-btn-hover-border-color: #bf4636;
  --bs-btn-focus-shadow-rgb: 241, 113, 96;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #bf4636;
  --bs-btn-active-border-color: #b34233;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #EF5844;
  --bs-btn-disabled-border-color: #EF5844;
}

.btn-outline-success {
  --bs-btn-color: #2AD167;
  --bs-btn-border-color: #2AD167;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2AD167;
  --bs-btn-hover-border-color: #2AD167;
  --bs-btn-focus-shadow-rgb: 42, 209, 103;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2AD167;
  --bs-btn-active-border-color: #2AD167;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2AD167;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2AD167;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #EEF0F3;
  --bs-btn-border-color: #EEF0F3;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #EEF0F3;
  --bs-btn-hover-border-color: #EEF0F3;
  --bs-btn-focus-shadow-rgb: 238, 240, 243;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #EEF0F3;
  --bs-btn-active-border-color: #EEF0F3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #EEF0F3;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #EEF0F3;
  --bs-gradient: none;
}

.btn-lg {
  --bs-btn-padding-y: 1rem;
  --bs-btn-padding-x: 1.75rem;
  --bs-btn-font-size: 1.125rem;
  --bs-btn-border-radius: 0.375rem;
}

.btn-sm {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.375rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}


.dropdown {
  position: relative;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #1f2937;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.5rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.5rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 1px 2px 0 rgba(85, 105, 135, 0.1);
  --bs-dropdown-link-color: #2A3342;
  --bs-dropdown-link-hover-color: #262e3b;
  --bs-dropdown-link-hover-bg: #D5DAE1;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #3B82F6;
  --bs-dropdown-link-disabled-color: #556987;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #4D5F7A;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.75rem;
  --bs-nav-link-font-size: 1rem;
  --bs-nav-link-font-weight: 500;
  --bs-nav-link-color: #2A3342;
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #4D5F7A;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.5rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: transparent;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.navbar {
  --bs-navbar-padding-x: 1rem;
  --bs-navbar-padding-y: 1.5rem;
  --bs-navbar-color: #000;
  --bs-navbar-hover-color: #111827;
  --bs-navbar-disabled-color: #6b7280;
  --bs-navbar-active-color: #111827;
  --bs-navbar-brand-padding-y: 0.65625rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.125rem;
  --bs-navbar-brand-color: #111827;
  --bs-navbar-brand-hover-color: #111827;
  --bs-navbar-nav-link-padding-x: 1.25rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.125rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23000' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.5rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.75rem;
  --bs-nav-link-font-size: 1rem;
  --bs-nav-link-font-weight: 500;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
}

.navbar-dark {
  --bs-navbar-color: #fff;
  --bs-navbar-hover-color: #e5e7eb;
  --bs-navbar-disabled-color: #6b7280;
  --bs-navbar-active-color: #e5e7eb;
  --bs-navbar-brand-color: #e5e7eb;
  --bs-navbar-brand-hover-color: #e5e7eb;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23fff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.5rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.5rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.pagination {
  --bs-pagination-padding-x: 1.25rem;
  --bs-pagination-padding-y: 0.625rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: #BBC3CF;
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #EEF0F3;
  --bs-pagination-border-radius: 0.5rem;
  --bs-pagination-hover-color: #BBC3CF;
  --bs-pagination-hover-bg: #EEF0F3;
  --bs-pagination-hover-border-color: #EEF0F3;
  --bs-pagination-focus-color: #BBC3CF;
  --bs-pagination-focus-bg: #EEF0F3;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(59, 130, 246, 0.25);
  --bs-pagination-active-color: #2AD167;
  --bs-pagination-active-bg: #fff;
  --bs-pagination-active-border-color: #EEF0F3;
  --bs-pagination-disabled-color: #4D5F7A;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #BBC3CF;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.badge {
  --bs-badge-padding-x: 0.75rem;
  --bs-badge-padding-y: 0.375rem;
  --bs-badge-font-size: 0.75rem;
  --bs-badge-font-weight: 500;
  --bs-badge-color: #2AD167;
  --bs-badge-border-radius: 9999px;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.5rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #D5DAE1;
  --bs-progress-border-radius: 0.5rem;
  --bs-progress-box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #3B82F6;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.5rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(59, 130, 246, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.5rem;
  --bs-toast-box-shadow: 0 1px 2px 0 rgba(85, 105, 135, 0.1);
  --bs-toast-header-color: #4D5F7A;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.75rem;
  --bs-modal-box-shadow: 0px 32px 64px -12px rgba(85, 105, 135, 0.08);
  --bs-modal-inner-border-radius: calc(0.75rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 1px 2px 0 rgba(85, 105, 135, 0.1);
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.5rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: "Poppins", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}

/* rtl:begin:ignore */

/* rtl:end:ignore */

/* rtl:begin:ignore */

/* rtl:end:ignore */

/* rtl:begin:ignore */

/* rtl:end:ignore */

/* rtl:begin:ignore */

/* rtl:end:ignore */

/* rtl:begin:ignore */

/* rtl:end:ignore */

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */


.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}

.link-secondary {
  color: #556987 !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: #44546c !important;
}

.link-success {
  color: #2AD167 !important;
}
.link-success:hover, .link-success:focus {
  color: #22a752 !important;
}

.link-warning {
  color: #F59E0B !important;
}
.link-warning:hover, .link-warning:focus {
  color: #c47e09 !important;
}

.link-danger {
  color: #EF5844 !important;
}
.link-danger:hover, .link-danger:focus {
  color: #bf4636 !important;
}

.link-secondary-light {
  color: #8896AB !important;
}
.link-secondary-light:hover, .link-secondary-light:focus {
  color: #6d7889 !important;
}

.link-light-light {
  color: #F7F8F9 !important;
}
.link-light-light:hover, .link-light-light:focus {
  color: #f9f9fa !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 1px 2px 0 rgba(85, 105, 135, 0.1) !important;
}

.shadow-sm {
  box-shadow: 0px 32px 64px -12px rgba(85, 105, 135, 0.08) !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.end-0 {
  right: 0 !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-success-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-light-rgb), var(--bs-border-opacity)) !important;
}

.border-light-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-5 {
  --bs-border-width: 5px;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-content-center {
  align-content: center !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.m-auto {
  margin: auto !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-4 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-20 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mt-7 {
  margin-top: 1.75rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mt-12 {
  margin-top: 3rem !important;
}

.mt-16 {
  margin-top: 4rem !important;
}

.mt-20 {
  margin-top: 5rem !important;
}

.mt-24 {
  margin-top: 6rem !important;
}

.mt-28 {
  margin-top: 7rem !important;
}

.mt-48 {
  margin-top: 12rem !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 0.75rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.mb-7 {
  margin-bottom: 1.75rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.mb-12 {
  margin-bottom: 3rem !important;
}

.mb-16 {
  margin-bottom: 4rem !important;
}

.mb-20 {
  margin-bottom: 5rem !important;
}

.mb-24 {
  margin-bottom: 6rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 0.75rem !important;
}

.ms-4 {
  margin-left: 1rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.mt-n6 {
  margin-top: -1.5rem !important;
}

.mt-n12 {
  margin-top: -3rem !important;
}

.me-n7 {
  margin-right: -1.75rem !important;
}

.mb-n6 {
  margin-bottom: -1.5rem !important;
}

.ms-n7 {
  margin-left: -1.75rem !important;
}

.ms-n12 {
  margin-left: -3rem !important;
}

.p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.p-9 {
  padding: 2.25rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.p-12 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.px-4 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-5 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-6 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-10 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-20 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-28 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.py-32 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pt-10 {
  padding-top: 2.5rem !important;
}

.pt-12 {
  padding-top: 3rem !important;
}

.pt-20 {
  padding-top: 5rem !important;
}

.pt-24 {
  padding-top: 6rem !important;
}

.pt-40 {
  padding-top: 10rem !important;
}

.pt-48 {
  padding-top: 12rem !important;
}

.pe-3 {
  padding-right: 0.75rem !important;
}

.pe-4 {
  padding-right: 1rem !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}

.pb-10 {
  padding-bottom: 2.5rem !important;
}

.pb-12 {
  padding-bottom: 3rem !important;
}

.pb-16 {
  padding-bottom: 4rem !important;
}

.pb-24 {
  padding-bottom: 6rem !important;
}

.pb-28 {
  padding-bottom: 7rem !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-4 {
  padding-left: 1rem !important;
}

.ps-12 {
  padding-left: 3rem !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-3 {
  gap: 0.75rem !important;
}

.gap-8 {
  gap: 2rem !important;
}

.fs-2 {
  font-size: calc(1.725rem + 5.7vw) !important;
}

.fs-3 {
  font-size: calc(1.575rem + 3.9vw) !important;
}

.fs-4 {
  font-size: calc(1.5rem + 3vw) !important;
}

.fs-5 {
  font-size: calc(1.425rem + 2.1vw) !important;
}

.fs-6 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-7 {
  font-size: calc(1.3125rem + 0.75vw) !important;
}

.fs-8 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-9 {
  font-size: 1.25rem !important;
}

.fs-10 {
  font-size: 1.125rem !important;
}

.fs-11 {
  font-size: 1rem !important;
}

.fs-12 {
  font-size: 0.875rem !important;
}

.fs-13 {
  font-size: 0.75rem !important;
}

.fs-14 {
  font-size: 0.6875rem !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

/* rtl:begin:remove */

/* rtl:end:remove */

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-light-rgb), var(--bs-text-opacity)) !important;
}

.text-success-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-light-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-light-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #4b5563 !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}
@media (min-width: 768px) {
  .d-md-inline {
    display: inline !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .mx-md-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-md-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-md-24 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mt-md-0 {
    margin-top: 0px !important;
  }
  .mt-md-6 {
    margin-top: 1.5rem !important;
  }
  .mt-md-8 {
    margin-top: 2rem !important;
  }
  .mt-md-10 {
    margin-top: 2.5rem !important;
  }
  .mt-md-16 {
    margin-top: 4rem !important;
  }
  .mt-md-96 {
    margin-top: 24rem !important;
  }
  .mb-md-0 {
    margin-bottom: 0px !important;
  }
  .mb-md-4 {
    margin-bottom: 1rem !important;
  }
  .mb-md-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-8 {
    margin-bottom: 2rem !important;
  }
  .mb-md-10 {
    margin-bottom: 2.5rem !important;
  }
  .mb-md-16 {
    margin-bottom: 4rem !important;
  }
  .mb-md-20 {
    margin-bottom: 5rem !important;
  }
  .p-md-10 {
    padding: 2.5rem !important;
  }
  .px-md-0 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .px-md-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .py-md-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .py-md-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-md-28 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-md-36 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .py-md-44 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }
  .pt-md-0 {
    padding-top: 0px !important;
  }
  .pt-md-8 {
    padding-top: 2rem !important;
  }
  .pt-md-20 {
    padding-top: 5rem !important;
  }
  .pt-md-96 {
    padding-top: 24rem !important;
  }
  .ps-md-0 {
    padding-left: 0px !important;
  }
}
@media (min-width: 992px) {
  .d-lg-block {
    display: block !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .mx-lg-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-lg-32 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .fs-2 {
    font-size: 6rem !important;
  }
  .fs-3 {
    font-size: 4.5rem !important;
  }
  .fs-4 {
    font-size: 3.75rem !important;
  }
  .fs-5 {
    font-size: 3rem !important;
  }
  .fs-6 {
    font-size: 2.25rem !important;
  }
  .fs-7 {
    font-size: 1.875rem !important;
  }
  .fs-8 {
    font-size: 1.5rem !important;
  }
}

.mw-xs {
  max-width: 20rem;
}

.mw-sm {
  max-width: 24rem;
}

.mw-md {
  max-width: 28rem;
}

.mw-3xl {
  max-width: 48rem;
}

.mw-4xl {
  max-width: 56rem;
}

.mw-5xl {
  max-width: 64rem;
}

.mw-6xl {
  max-width: 72rem;
}

.mw-7xl {
  max-width: 80rem;
}
@media (min-width: 768px) {
  .mw-md-xs {
    max-width: 20rem;
  }
  .mw-md-sm {
    max-width: 24rem;
  }
  .mw-md-md {
    max-width: 28rem;
  }
  .mw-md-lg {
    max-width: 32rem;
  }
  .mw-md-xl {
    max-width: 36rem;
  }
  .mw-md-2xl {
    max-width: 42rem;
  }
  .mw-md-3xl {
    max-width: 48rem;
  }
  .mw-md-4xl {
    max-width: 56rem;
  }
  .mw-md-5xl {
    max-width: 64rem;
  }
  .mw-md-6xl {
    max-width: 72rem;
  }
  .mw-md-7xl {
    max-width: 80rem;
  }
}
@media (min-width: 1200px) {
  .mw-xl-2xl {
    max-width: 42rem;
  }
  .mw-xl-5xl {
    max-width: 64rem;
  }
  .mw-xl-7xl {
    max-width: 80rem;
  }
}
*, *::before, *::after {
  box-sizing: inherit;
}

html {
  /* this makes sure the padding and the border is included in the box sizing */
  box-sizing: border-box;
  overflow-x: clip;
}

html body {
  padding: 0;
  margin: 0;
  overflow-x: inherit;
  height: 100%;
  width: 100vw;
}

.btn-dark-light {
  --bs-btn-color: #fff;
  --bs-btn-bg: #404F65;
  --bs-btn-border-color: #404F65;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #364356;
  --bs-btn-hover-border-color: #333f51;
  --bs-btn-focus-shadow-rgb: 93, 105, 124;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #333f51;
  --bs-btn-active-border-color: #303b4c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #404F65;
  --bs-btn-disabled-border-color: #404F65;
}

.team-round-image {
  height: 170px;
  width: 170px;
  border-radius: 50%;
}

.input-lg {
  padding: 0.5rem 1rem; /* Same padding as btn-lg */
  font-size: 1.25rem; /* Same font-size as btn-lg */
  line-height: 1.5; /* Standard line height for readability */
}

.select-lg {
  padding: 0.5rem 1rem; /* Padding with enough space for text */
  font-size: 1.25rem; /* Larger font size for better readability */
  height: auto; /* Adjust height to fit content */
  line-height: 1.5; /* Standard line height */
}

/* Styles for dropdown items */
.dropdown-item-custom {
  font-size: 1.1rem;
  padding: 10px 15px;
}

.dropdown-item-custom:hover {
  color: #364356; /* or any other color that is visible on dark background */
}

/* Additional styling for the dropdown menu */
.dropdown-menu {
  border-radius: 0.5rem; /* Rounded corners */
  color: #fff; /* White text */
}

.my-toast {
  --bs-toast-color: #fff;
  --bs-toast-bg: #2AD167;
  --bs-toast-border-color: #2AD167;
  background-color: var(--bs-toast-bg);
  color: var(--bs-toast-color);
  border-color: var(--bs-toast-border-color);
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  min-width: 250px;
  z-index: 9999;
}

.my-toast .toast-body {
  font-size: 1.1rem; /* Larger text */
}

.my-toast .btn-close {
  color: var(--bs-toast-color);
}

/* Typing dots animation */
.typing {
  position: relative;
  display: flex;
  align-items: center;
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
.html-live-preview * {
  color: white !important;
}

.blog-article img {
  max-width: 100%;
  height: auto;
}

.blog-article figure {
  display: block;
  text-align: center;
  margin: auto;
}

.text-tiny {
  font-size: 0.8rem;
}

.text-small {
  font-size: 1rem;
}

.text-big {
  font-size: 1.3rem;
}

.text-huge {
  font-size: 1.6rem;
}

.btn-xl {
  --bs-btn-padding-y: 1.5rem;
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-font-size: 1.9rem;
  --bs-btn-border-radius: 0.75rem;
}

/* Style for the play button */
.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centers the button exactly in the middle */
  z-index: 10; /* Ensures the button is above the video layer */
  display: block; /* Ensures proper dimension calculations */
  cursor: pointer;
}

.rdw-image-imagewrapper > img {
  max-width: 100%;
  height: auto;
}

.rdw-option-wrapper > img {
  height: 20px;
  width: 25px;
}

@media (max-width: 767.98px) {
  .webinar-player-col {
    padding-bottom: 56.25% !important;
  }
  .purchase-button {
    width: 100% !important;
  }
}
/* On desktop (≥768px): use 37.5% so it matches video height */
@media (min-width: 768px) {
  .webinar-player-col {
    padding-bottom: 0 !important;
  }
}
.hover-border-secondary-dark:hover {
  /* If you literally want the border color changed: */
  border: none !important;
  box-shadow: none !important;
}

/* Or if you want the background color on hover instead: */
.hover-border-secondary-dark:hover {
  background-color: #2a3342 !important;
}

.rdw-option-wrapper.rdw-option-active {
  box-shadow: none !important;
  background-color: #2a3342 !important;
}
